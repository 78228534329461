jQuery(document).ready(function($){
    /**
     * Navbar
     */
    $("nav #navbar-toggle-button").click(function () {
        $(this).toggleClass("active");
        $("nav #navbar-toggle").toggleClass("collapse");
    });

    // Resize navbar
    $(document).on("scroll", function() {
        if($(document).scrollTop()>50) {
            $("nav").addClass("navbar-scroll");
        } else {
            $("nav").removeClass("navbar-scroll");
        }
    });

    /**
     * Lightbox
     */
    $(function () {
        var photogallery = $("a[rel^='lightbox']");
        if (photogallery.length > 0) {
            photogallery.simpleLightbox();
        }
    });

    $(".open-contact").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $("#modal-contact").show();
        $("body").addClass('body-modal-active');
    });

    $("#close-contact").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $("#modal-contact").hide();
        $("body").removeClass('body-modal-active');
    });
});